<template>
  <div>
    <!-- Footer Start -->
    <!-- <section
      class="footer"
      style="background-image: url(assets/images/footer-bg.png)"
    > -->
      <div class="main-footer">
        <div class="row" style="width: 100%;">
          <div class="col-lg-4">
            <div class="mb-5">
              <a class="navbar-bran logo ml-5 pl-5 logo_img" href="/">
                <img
                  src="@/assets/images/Logo1.png"
                  alt
                  class="logo-dark"
                  height="48"
                />
                <div class="logo-list">
                  <span class="logo-title">O‘zbekiston Respublikasi</span>
                  <span class="logo-title">Madaniyat vazirligi</span>
                  <!-- <span class="logo-title">IJODKORLAR.UZ</span>
                  <span class="logo-text">Ijodkorlar yagona portali</span> -->
                </div>
              </a>
              <!-- <img v-if="lang != 'ru'" src="@/assets/images/design/logouzbdark.png" alt class height="38" /> -->
              <!-- <p class="text-white-50 my-4 font-size-15">
                Программный комплекс «Опека, попечительства и усыновления
                (удочерения) детей»
              </p> -->
              <!-- <ul class="list-inline footer-social-icon-content">
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <facebook-icon></facebook-icon>
                  </a>
                </li>
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <twitter-icon></twitter-icon>
                  </a>
                </li>
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <instagram-icon></instagram-icon>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <linkedin-icon></linkedin-icon>
                  </a>
                </li>
              </ul> -->
            </div>
          </div>
          <div class="col-lg-7 offset-lg-1">
            <div class="row">
              <!-- <div class="col-md-4">
                <h6 class="text-white text-uppercase mb-4">About Us</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Works</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Strategy</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Releases</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Press</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Mission</a>
                  </li>
                </ul>
              </div> -->
              <div class="col-md-4">
                <!-- <h6 class="text-white text-uppercase mb-3">Customers</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Tranding</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Popular</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Customers</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Features</a>
                  </li>
                </ul> -->
              </div>
              <div class="col-md-8">
                <h6 class="text-white text-uppercase mb-3">Контакты</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a  href="tel:+998555023375" class="footer-link"
                      >+998 55 502 33 75</a
                    >
                  </li>
                  <li>
                    <a target="_blank" href="https://www.google.com/maps/search/100029+tashkent+city+amir+temur+107b/@41.3373509,69.2812884,16.38z?entry=ttu" class="footer-link">
                     100029 Toshkent shahri, Amir Temur 107b
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@madaniyat.uz" target="_blank" class="footer-link">
                       info@madaniyat.uz</a
                    >
                  </li>
                  <!-- <li>
                    <a href="javascript: void(0);" class="footer-link"
                      >sport@exat.uz</a
                    >
                  </li> -->
                  <!-- <li>
                    <a href="javascript: void(0);" class="footer-link">Guide</a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </section> -->
    <!-- <div class="footer-alt py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="text-white-50 font-size-15 mb-0">
                {{ new Date().getFullYear() }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Footer End -->
  </div>
</template>
<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
} from "vue-feather-icons";
export default {
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
  },
  computed: {
    lang() {
      return localStorage.getItem("locale") || "uz_latn";
    },
  },
};
</script>
<style lang="scss">
.logo_img {
  display: flex;
  align-items: center;
  color: #fff;
  .logo-list {
    display: grid;
    margin-left: 15px;
  }
  .logo-title {
    font-size: 17px !important;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #fff;
  }
  .logo-texst {
    font-size: 10px !important;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #fff !important;
  }
}
.footer-link {
  color: #fff;
}
</style>
